import React from "react";
import { DownloadWrap } from "../../styles/DownloadStyle";
import { AwardDownloadCard } from "../MiniComponents/AwardDownloadCard";
import logo3 from "../../images/Logo3.png";
import logo2 from "../../images/Logo2.png";
import logo1 from "../../images/Logo1.png";
import bg3 from "../../images/awardBg3.png";
import bg2 from "../../images/awardBg2.png";
import bg1 from "../../images/awardBg1.png";
import celebrate from "../../images/celebrate.png";
import { useSelector } from "react-redux";

export const DownloadAward = (props) => {
   const trophy = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.trophy
  );

  const totalDownloads = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.totalDownloads
  );

  const orgName = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.orgName
  );

  console.log(orgName, "+++++orgName");
  return (
    <DownloadWrap>
      <div id="my-logo">
        <AwardDownloadCard
          bg={trophy === 1 ? bg1 : trophy === 2 ? bg2 : trophy === 3 ? bg3 : ""}
          awardLogo={
            trophy === 1
              ? logo1
              : trophy === 2
              ? logo2
              : trophy === 3
              ? logo3
              : ""
          }
          award={
            trophy === 1
              ? "Silver"
              : trophy === 2
              ? "Gold"
              : trophy === 3
              ? "Platinum"
              : ""
          }
          coachingName={orgName}
          downloads={
            trophy === 1
              ? "5000"
              : trophy === 2
              ? "25000"
              : trophy === 3
              ? "100000"
              : ""
          }
        />
      </div>
      <div className="awardCelebrate">
        <img className="awardCelebrate__img" src={celebrate} alt="celebrate" />
        <div className="awardCelebrate__para">
          Celebrate this special moment with your loved ones
        </div>
      </div>
      {
          <button className="downloadAward" onClick={() => props.downloadAward()}>
          Download image to share
          </button>
          
      }
      <div className="borderBottom"></div>
    </DownloadWrap>
  );
};
