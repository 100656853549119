import React from 'react';
import { MileStoneCardWarp } from '../../styles/MileStoneCardStyle';

export const MileStoneCard = (props) => {
    return (
        <MileStoneCardWarp>
            <div className="mileStone__col1">
                <img className="mileStone__col1--img" src={props.data.img} alt="award" />
            </div>
            <div className="mileStone__col2">
                <div className="mileStone__col2--head">{props.data.head}</div>
                <div className="mileStone__col2--para">{props.data.para}</div>
            </div>
        </MileStoneCardWarp>
    )
}