import React, { Component } from "react";
import { AwardDetail } from "../components/AwardPage/AwardDetail";
import { DownloadAward } from "../components/AwardPage/DownloadAward";
import { Header } from "../components/NoAwardPage/Header";
import { AwardWarp } from "../styles/AwardStyle";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

class AwardPage extends Component {
  constructor() {
    super();
    this.state = {
      newFile: "",
    };
  }

  downloadAward = () => {
    let _this = this;
    domtoimage.toBlob(document.getElementById("my-logo")).then((blob) => {
      _this.setState({ newFile: blob });

      var anch = btoa('https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/dmo/a1d8ffc2-6a58-4fe6-bd83-79a462c77716.jpg')
      // a.href = "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/dmo/a1d8ffc2-6a58-4fe6-bd83-79a462c77716.jpg";

      // if (
      //   window &&
      //   window.webkit &&
      //   window.webkit.messageHandlers &&
      //   window.webkit.messageHandlers.onDeeplinkExecuted
      // ) {
      //   window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
      //     `UTIL_DOWNLOAD_IMAGE,${anch},`
      //   );
      // }

      // if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      //   window.mobile.onDeeplinkExecuted(`UTIL_DOWNLOAD_IMAGE,${anch},`);
      // }


      let url = window.location.href + `?token=${localStorage.getItem("educatorAwardToken")}` + `&orgName=${localStorage.getItem("ORGNAME")}`

      if (
        window &&
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.onDeeplinkExecuted
      ) {
        window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
          `UTIL_BROWSER,${url},''`
        );
      }
  
      if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
        window.mobile.onDeeplinkExecuted(`UTIL_BROWSER,${url},''`);
      }

      saveAs(blob, "my-award.png");
    });
  };

  downloadAward2 = () => {
    let _this = this;
    domtoimage.toBlob(document.getElementById("my-logo")).then((blob) => {
      _this.setState({ newFile: blob });

      var reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = function() {
          var base64data = reader.result;                
          console.log(base64data);

          var anch = btoa(base64data)
        console.log(base64data,'aaaa<<<<<', anch)
        if (
          window &&
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.onDeeplinkExecuted
        ) {
          window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
            `UTIL_DOWNLOAD_IMAGE,${anch},`
          );
        }
  
        if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
          window.mobile.onDeeplinkExecuted(`UTIL_DOWNLOAD_IMAGE,${anch},`);
        }
  
             saveAs(blob, "my-award.png");

      }


      

    });
  };

  render() {
    console.log(this.state.newFile);
    return (
      <AwardWarp>
        <Header />
        <DownloadAward downloadAward={this.downloadAward} downloadAward2={this.downloadAward2}/>
        <AwardDetail />
      </AwardWarp>
    );
  }
}

export default AwardPage;
