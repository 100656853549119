import React from "react";
import { AwardDetailWrap } from "../../styles/AwardDetailStyle";
import Award4 from "../../images/Award4.png";
import Award3 from "../../images/Award3.png";
import Award2 from "../../images/Award2.png";
import Award1 from "../../images/Award1.png";
import { StatusDetailCard } from "../../components/MiniComponents/StatusDetailCard";
import { Remainder } from "../MiniComponents/Remainder";
import { useSelector } from "react-redux";

export const AwardDetail = () => {
  const trophy = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.trophy
  );
  const moreRequiredDownloads = useSelector(
    (state) =>
      state.getAwardData.data && state.getAwardData.data.moreRequiredDownloads
  );
  const totalDownloads = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.totalDownloads
  );
  return (
    <AwardDetailWrap>
      <StatusDetailCard
        award={
          trophy === 1
            ? Award2
            : trophy === 2
            ? Award3
            : trophy === 3
            ? Award4
            : Award1
        }
        ambition={
          "We know you're ambitious, so here's the next milestone for our partnership! "
        }
        appDownloads={moreRequiredDownloads}
        awardName={
          trophy === 1
            ? "Gold"
            : trophy === 2
            ? "Platinum"
            : trophy === 3
            ? "Diamond"
            : "Silver"
        }
        currentAppDownloads={totalDownloads}
      />
      <Remainder />
    </AwardDetailWrap>
  );
};
