import React from 'react';
import Award1 from "../../images/Award1.png";
import Award2 from "../../images/Award2.png";
import Award3 from "../../images/Award3.png";
import { MileStoneWarp } from '../../styles/MileStone';
import { MileStoneCard } from '../MiniComponents/MileStoneCard';
import { Remainder } from '../MiniComponents/Remainder';

export const MileStone = () => {
    const dataArr = [
        {
            img:Award1,
            head:"Silver Educator Award",
            para:"5,000 app downloads",
        },
        {
            img:Award2,
            head:"Gold Educator Award",
            para:"25,000 app downloads",
        },
        {
            img:Award3,
            head:"Platinum Educator Award",
            para:"1,00,000 app downloads",
        },
    ]
    return (
        <MileStoneWarp>
            <div className="mileHeader">The Educator Award Milestones</div>
            <div>
                {dataArr.map((data,index) => {
                    return (
                        <MileStoneCard key={index} data={data} />
                    )
                })}
            </div>
            <Remainder />
        </MileStoneWarp>
    )
}