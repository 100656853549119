import React from "react";
import logo from "../../images/sLogo.png";
import { AwardDownloadWarp } from "../../styles/AwardDownloadStyle";

export const AwardDownloadCard = (props) => {
  const coachingName = localStorage.getItem("ORGNAME");
  return (
    <AwardDownloadWarp bg={props.bg}>
      <img className="awardLogo" src={props.awardLogo} alt="logo" />
      <div className="awardName">{props.award} Educator Award</div>
      <div className="presented">Presented to</div>
      <div className="coachingName">{props.coachingName}</div>
      <div className="downloads">
        For surpassing {props.downloads} app downloads
      </div>
      <img className="classplusLogo" src={logo} alt="logo" />
    </AwardDownloadWarp>
  );
};
