import React, { Component } from "react";
import { Header } from "../components/NoAwardPage/Header";
import { StatusCard } from "../components/NoAwardPage/StatusCard";
import { NoAwardWarp } from "../styles/NoAwardStyle";
import { AppDownloads } from "../components/HomePage/AppDownloads";

class NoAwardPage extends Component {
  render() {
    return (
      <NoAwardWarp>
        <Header />
        <StatusCard />
        <AppDownloads />
      </NoAwardWarp>
    );
  }
}

export default NoAwardPage;
