import styled from 'styled-components';

export const FooterWrap = styled.div`
    position:fixed;
    bottom:0;
    z-index:1;
    width: 100%;
    padding: 1.6rem;
    padding-top:0.8rem;
    background: #2B2B2B;
    box-shadow: 0px -2px 4px rgba(41, 41, 42, 0.04);

    .footerPara {
        font-weight: 450;
        font-size: 1.7rem;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom:0.9rem;
    }

    .footerButton {
        background: #00AFFF;
        border-radius: 4px;
        border:none;
        width:100%;
        font-weight: 700;
        font-size: 2rem;
        line-height: 133%;
        text-align: center;
        color: #FFFFFF;
        padding: 1.2rem 0;
    }
`;