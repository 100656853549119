import React, { Component } from "react";
import { AppDownloads } from "../components/HomePage/AppDownloads";
import { Banner } from "../components/HomePage/Banner";
import { Footer } from "../components/HomePage/Footer";
import { Header } from "../components/HomePage/Header";
import { MileStone } from "../components/HomePage/MileStone";
import { HomeWarp } from "../styles/HomeStyle";
import { connect } from "react-redux";
import Loader from "../utils/Loader/Loader";

class HomePage extends Component {
  render() {
    return !this.props.isLoader ? (
      <Loader />
    ) : (
      <HomeWarp>
        <Header />
        <Banner />
        <MileStone />
        <AppDownloads />
        <Footer />
      </HomeWarp>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoader: state.getAwardData.data && state.getAwardData.data,
  };
}

export default connect(mapStateToProps)(HomePage);
