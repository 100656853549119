import * as api from "../../api/index.js";

export const getAwardDataFromApi = () => async (dispatch) => {
  try {
    const { data } = await api.fetchAwardDataFromApi();
    dispatch({ type: "FETCH_ALL", payload: data });
  } catch (error) {
    console.log(error);
  }
};
