import React from "react";
import Award1 from "../../images/Award1.png";
import bulb from "../../images/bulb.png";
import { StatusCardWrap } from "../../styles/StatusCardStyle";
import { StatusDetailCard } from "../MiniComponents/StatusDetailCard";
import { Remainder } from "../MiniComponents/Remainder";
import { useSelector } from "react-redux";

export const StatusCard = () => {
  const moreRequiredDownloads = useSelector(
    (state) =>
      state.getAwardData.data && state.getAwardData.data.moreRequiredDownloads
  );
  const totalDownloads = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.totalDownloads
  );
  return (
    <StatusCardWrap>
      <StatusDetailCard
        award={Award1}
        ambition={"We know your ambition, so here’s the magic number for you!"}
        appDownloads={moreRequiredDownloads}
        awardName={"Silver"}
        currentAppDownloads={totalDownloads}
      />
      <div className="remainder">
        <img className="remainder__img" src={bulb} alt="bulb" />
        <div className="remainder__para">
          25 institutes from your state have already won an Educator Award
        </div>
      </div>
      <Remainder />
    </StatusCardWrap>
  );
};
