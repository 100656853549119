import React from 'react';
import { BannerWarp } from '../../styles/BannerStyle';

export const Banner = () => {
    return (
        <BannerWarp>
            <div className="banner">
                <div className="banner__header">Celebrating the spirit of independent educators</div>
                <div className="banner__para1">Few have the courage to go against the wind. To believe in their ambition. And to keep trying till they succeed. The ones that do, are the special ones.</div>
                <div className="banner__para2">And it's our privilege to honor the special ones - our educators - with these newly launched Classplus Educator Awards.</div>
            </div>
        </BannerWarp>
    )
}