import React from "react";
import { StatusDetailWrap } from "../../styles/StatusDetailStyle";

export const StatusDetailCard = (props) => {
  return (
    <StatusDetailWrap>
      <div className="row1">
        <img className="row1--img" src={props.award} alt="trophy" />
      </div>
      <div className="row2">{props.ambition}</div>
      {props.appDownloads ? (
        <div className="row3">{props.appDownloads} more app downloads</div>
      ) : (
        <div className="row3Shimmer shimmer"></div>
      )}
      <div className="row4">
        to receive the <span className="row4--blue">{props.awardName} Educator Award</span>
      </div>
      <div className="row5">Your current app downloads:</div>
      {props.currentAppDownloads ? (
        <div className="row6">{props.currentAppDownloads}</div>
      ) : (
        <div className="row6Shimmer shimmer"></div>
      )}
    </StatusDetailWrap>
  );
};
