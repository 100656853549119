import React from 'react';
import logo from "../../images/classplusLogo.png";
import { HeaderWarp } from '../../styles/HeaderStyle';

export const Header = () => {
    return (
        <HeaderWarp>
            <img className="homeLogo" src={logo} alt="logo" />
        </HeaderWarp>
    )
}