import React, { useEffect } from "react";
import speaker from "../../images/speaker.png";
import phone from "../../images/phone.png";
import {
  AppDownloadCardWarp,
  AppDownloadWarp,
} from "../../styles/AppDownloadStyle";
import { useSelector } from "react-redux";

export const AppDownloads = () => {
  const contact = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.contact
  );
  const shareText = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.shareText
  );
  const dataArr = [
    {
      head: "Market your app",
      para: "Share your app link on all your social media & chat apps!",
      button: "Share Now",
      img: speaker,
      backgroundColor: "#7B98FF",
      btnBg: "rgba(0, 0, 0, 0.2)",
    },
    {
      head: "Speak with your Customer Success Manager",
      para: "Call your CSM and they will help guide you on growing app downloads",
      button: "Call Now",
      img: phone,
      backgroundColor: "#04B0CA",
      btnBg: "rgba(0, 0, 0, 0.2)",
    },
  ];

  const gotowhatsAppShare = (text) => {
    window.open(`https://api.whatsapp.com/send?text=${text}`);
  };

  return (
    <AppDownloadWarp>
      <div className="appHeader">How to increase your app downloads?</div>
      <div className="appContent">
        {dataArr.map((data, index) => {
          return (
            <AppDownloadCardWarp
              key={index}
              bg={data.backgroundColor}
              btnBg={data.btnBg}>
              <div className="col1">
                <div className="col1__head">{data.head}</div>
                <div className="col1__para">{data.para}</div>
                {data.button === "Call Now" ? (
                  <button className="col1__btn">
                    <a className="col1__btn--href" href={`tel:${contact}`}>
                      {data.button}
                    </a>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      gotowhatsAppShare(shareText);
                    }}
                    className="col1__btn">
                    {data.button}
                  </button>
                )}
              </div>
              <div className="col2">
                <img className="col2__img" src={data.img} alt="icons" />
              </div>
            </AppDownloadCardWarp>
          );
        })}
      </div>
    </AppDownloadWarp>
  );
};
