import React from 'react';
import { RemaiderWrap } from '../../styles/RemainderStyle';
import info from "../../images/info.png";

export const Remainder = () => {
    return (
        <RemaiderWrap>
            <img className="img" src={info} alt="info" />
            <div className="para">App downloads means qualified student sign-ups</div>
        </RemaiderWrap>
    )
}