import styled from "styled-components";

export const HeaderWarp = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0.6rem 2rem;
  background: #2b2b2b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  align-items: center;

  .homeLogo {
    width: 60%;
  }

  .headerBack {
    margin-right: 2rem;
  }

  .headerPara {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #ffffff;
    padding: 1.7rem;
    padding-top: 2rem;
  }
`;
