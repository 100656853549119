import styled from "styled-components";

export const AwardDownloadWarp = styled.div`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;

  .awardLogo {
    padding-top: 5.3rem;
  }

  .awardName {
    width: 100%;
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    padding-top: 3rem;
  }

  .presented {
    width: 100%;
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    padding-top: 1.6rem;
  }

  .coachingName {
    width: 100%;
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    padding-top: 1.6rem;
  }
  .downloads {
    width: 100%;
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    padding-top: 1.6rem;
  }

  .classplusLogo {
    padding: 4.7rem 0 6.5rem;
  }
`;
