import styled from "styled-components";
import bg from "../images/appBg.png";

export const AppDownloadWarp = styled.div`
  padding: 6rem 2.4rem 4rem;
  background-image: url(${bg});
  background-position: center;
  background-size: cover top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  .appHeader {
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 150%;
    width: 80%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 4rem;
  }
`;

export const AppDownloadCardWarp = styled.div`
  background: ${(props) => props.bg};
  margin-bottom: 1.8rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 2fr 1fr;

  .col1 {
    font-style: normal;
    line-height: 130%;
    color: #ffffff;
    padding: 2.4rem 0 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    &__head {
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 0.8rem;
    }
    &__para {
      font-weight: 450;
      font-size: 1.8rem;
      margin-bottom: 1.6rem;
    }
    &__btn {
      background: ${(props) => props.btnBg};
      padding: 0.9rem 2.2rem;
      font-weight: 700;
      font-size: 1fr.4rem;
      line-height: 150%;
      color: #ffffff;
      border-radius: 4px;
      border: none;

      &--href {
        text-decoration: none;
        color: inherit;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .col2 {
    &__img {
      width: 100%;
    }
  }
`;
