import styled from "styled-components";

export const StatusDetailWrap = styled.div`
  background: #2b2b2b;
  border-radius: 10px;
  padding: 2.4rem;

  .row1 {
    position: relative;
    padding: 5rem 0;
    &--img {
      position: absolute;
      bottom: 0;
    }
  }

  .row2 {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    color: #ffffff;
    margin-top: 2rem;
  }

  .row3 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 150%;
    color: #84e292;
    margin-top: 0.8rem;
  }

  .row4 {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    color: #ffffff;
    margin-top: 1.2rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.16);
    &--blue {
      color: #399ced;
    }
  }

  .row5 {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    color: #ffffff;
    margin-top: 1.6rem;
  }

  .row6 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 150%;
    color: #ffffff;
    margin-top: 0.8rem;
  }

  .row3Shimmer {
    width: 60%;
    height: 1.2rem;
    margin-top: 0.8rem;
  }

  .row6Shimmer {
    width: 20%;
    height: 1.8rem;
    margin-top: 0.8rem;
  }

  .shimmer {
    background: #4a4a4a;
    background-image: linear-gradient(
      to right,
      #4a4a4a 0%,
      #edeef1 20%,
      #4a4a4a 40%,
      #4a4a4a 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }
`;
