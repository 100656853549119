import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FooterWrap } from "../../styles/FooterStyle";

export const Footer = () => {
  const history = useHistory();
  const trophy = useSelector(
    (state) => state.getAwardData.data && state.getAwardData.data.trophy
  );
  return (
    <FooterWrap>
      <div className="footerPara">
        {trophy === 0
          ? "You can win a milestone award!"
          : "Congratulations! You have won a milestone award"}
      </div>
      <button
        className="footerButton"
        onClick={() =>
          trophy == 0
            ? history.push("/status")
            : history.push("/award")
        }
      >
        {trophy === 0 ? "View my status" : "View my award"}
      </button>
    </FooterWrap>
  );
};
