import React, { Component } from "react";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import HomePage from "./routes/HomePage";
import NoAwardPage from "./routes/NoAwardPage";
import AwardPage from "./routes/AwardPage";
import { getQuery } from "./utils";
import { connect } from "react-redux";
import { getAwardDataFromApi } from "./redux/actions/getApiActions";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    let educatorAwardToken =
      getQuery()["token"]
    localStorage.setItem("educatorAwardToken", educatorAwardToken);
    this.props.getApiDataOfAwards();
    // let token = localStorage.getItem("TOKEN");
    // let authTokenData = this.parseToken(token);
    // console.log(authTokenData, "+++++authTokenData");
    localStorage.setItem("ORGNAME", getQuery()["orgName"]);
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route
                exact
                path="/status"
                component={NoAwardPage}
              />
              <Route exact path="/award" component={AwardPage} />
              <Route exact render={() => <Redirect to="/" />} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchData: state.getAwardData.data && state.getAwardData.data,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getApiDataOfAwards: () => dispatch(getAwardDataFromApi()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
