import styled from 'styled-components';
import bg from "../images/mileStoneBg.png";

export const MileStoneWarp = styled.div`
    background:url(${bg});
    background-size:cover;
    background-position:center top;
    background-repeat:no-repeat;
    padding: 6rem 2rem 2.3rem;

    .mileHeader {
        font-style: normal;
        font-weight: bold;
        font-size: 2.6rem;
        line-height: 150% ;
        text-align: center;
        color: #FFFFFF;
        margin-bottom:6rem;
    }
`;