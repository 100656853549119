import styled from 'styled-components';

export const MileStoneCardWarp = styled.div`
    display:grid;
    grid-template-columns:1fr 2fr;
    grid-gap:2.4rem;
    background: #2B2B2B;
    border-radius: 6px;
    padding:2.4rem;
    margin-bottom:4.8rem;

    .mileStone{
        &__col1 {
        position:relative;
            &--img {
                width:95%;
                position:absolute;
                bottom:0;
            }
        }

        &__col2 {
            &--head{
                font-weight: bold;
                font-size: 2.2rem;
                line-height: 150%;
                color: #FFFFFF;
                width: 70%;
            }
            &--para {
                margin-top:0.8rem;
                font-weight: 500;
                font-size: 2rem;
                line-height: 150%;
                color: #FFFFFF;
            }
        }
    }
`;