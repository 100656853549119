import styled from 'styled-components';

export const DownloadWrap = styled.div`
    padding:2.4rem 2.4rem 0;
    background: #212121;

    .awardCelebrate {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        margin-top:4rem;

        &__para {
            font-weight: 450;
            font-size: 1.6rem;
            line-height: 150%;
            color: #FFFFFF;
            margin-left:0.8rem;
        }

        &__img {
            width:5%;
        }
    }

    .downloadAward {
        width:100%;
        margin-top:1.6rem;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 133%;
        text-align: center;
        color: #FFFFFF;
        background: #00AFFF;
        border:none;
        padding: 1.2rem 0;
        border-radius: 4px; 
        &:focus{
            outline:none;
        }
    }

    .borderBottom {
        margin-top:4rem;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
    }
`;