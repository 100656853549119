import styled from 'styled-components';

export const StatusCardWrap = styled.div`
    padding: 6rem 2rem 2rem;
    background: #212121;
    margin-top:7rem;

    .remainder{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:flex-start;
        background: #2B2B2B;
        border-radius: 4px;
        margin-top:2.4rem;
        padding:1rem 1.2rem;

        &__para {
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 150%;
            color: #FFFFFF;
            margin-left: 1.6rem;
        }
    }

    .footer {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width: 100%;
        padding-top:1.6rem;

        &__para {
            font-weight: 450;
            font-size: 1.4rem;
            line-height: 150%;
            color: #FFFFFF;
            margin-left:1rem;
        }
    }
`;