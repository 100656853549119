import styled from "styled-components";
import bg from "../images/bannerImg.png";

export const BannerWarp = styled.div`
  margin-top: 7rem;
  background: url(${"https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/tutor/dev/bannerImg.png"});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 35rem;

  .banner {
    background: radial-gradient(
      100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: inset -3.97313px -3.97313px 198.656px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(30px);
    border-radius: 0px 120px 0px 0px;
    padding: 3.2rem 2.4rem 4rem;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-bottom:none;
    border-left:none;

    &__header {
      font-style: normal;
      font-weight: bold;
      font-size: 2.6rem;
      line-height: 150%;
      color: #ffffff;
      width: 85%;
    }

    &__para1,
    &__para2 {
      font-weight: 450;
      font-size: 1.8rem;
      line-height: 150%;
      color: #ffffff;
      margin-top: 1.6rem;
      width: 90%;
    }
  }
`;
