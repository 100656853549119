import React from "react";
import back from "../../images/back.png";
import { HeaderWarp } from "../../styles/HeaderStyle";
import { useHistory } from "react-router-dom";

export const Header = () => {
  const history = useHistory();
  return (
    <HeaderWarp>
      <img
        className="headerBack"
        onClick={() => history.push("/")}
        src={back}
        alt="back"
      />
      <div className="headerPara">Back to Awards Home</div>
    </HeaderWarp>
  );
};
