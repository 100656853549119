import React from "react";
import Spinner from "./Spinner/Spinner";
import "./Loader.css"
export default function Loader() {
  return (
    <div className="Load">
      <Spinner />
    </div>
  );
}
