import styled from 'styled-components';

export const RemaiderWrap = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width: 100%;
    padding-top:1.6rem;

    .para {
        font-weight: 450;
        font-size: 1.5rem;
        line-height: 150%;
        color: #FFFFFF;
        margin-left:1rem;
    }
`;