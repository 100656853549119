import axios from "axios";
import { baseUrl } from "../config";
import { getQuery } from "../utils";

// eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuICIsImVtYWlsIjoiZ3VuamFuQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTcxMTE1MzA3OSIsInR5cGUiOjMsImlhdCI6MTYxNDE2NTg5MSwiZXhwIjoxNjE0NzcwNjkxfQ.VOt9ihsokJTPmqdZRIZhaEKuW6swHOmGkzipHtrGq6SQ8i4mRJgXsFIsOV85iUWn

const promoHeaders = {
  "Content-Type": "application/json",
  "Api-Version": 11,
  "x-access-token": localStorage.getItem("educatorAwardToken"),
};

export const fetchAwardDataFromApi = () =>
  axios
    .get(`${baseUrl}/v3/trophy`, {
      headers: promoHeaders,
    })
    .then((response) => {
      return response;
    });
